import { graphql, Link } from 'gatsby';
import React, { FC } from 'react';
import { Layout } from 'src/components/Layout';
import { EpisodesQuery } from 'src/graphqlTypes';

interface EpisodesProps {
    data: EpisodesQuery;
}

const Episodes: FC<EpisodesProps> = ({ data }) => {
    return (
        <Layout>
            {data.allContentfulEpisode.nodes.map((episode) => (
                <div key={episode.id}>
                    <Link
                        to={`/season-${episode.season && episode.season[0]?.seasonNumber}/episode-${
                            episode.episodeNumber
                        }`}
                    >
                        Episode {episode.episodeNumber}
                    </Link>
                </div>
            ))}
        </Layout>
    );
};

export const query = graphql`
    query Episodes {
        allContentfulEpisode {
            nodes {
                id
                episodeNumber
                season {
                    id
                    seasonNumber
                }
            }
        }
    }
`;

export default Episodes;
